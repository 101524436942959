.rootTouchBuffer {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -22px;
  margin-left: -22px;

  /* Layout */
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
}

.visibleHandle {
  position: absolute;
  top: 5px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  @media (max-width: 767px) {
    cursor: default;
  }
  border-radius: 35px;
  background: #000;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 24px 1px rgb(0 0 0 / 8%),
    0px 2px 2px 0px rgb(0 0 0 / 14%);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
}

.dragged {
  transform: scale(1.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

.draggedValue {
  position: absolute;
  top: 50%;
  left: 39%;
  transform: translate(-50%, -50%);
  color: #00c3a0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
