.root {}

.inputError {
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(195, 12, 0, 0.1) inset;
}

.inputWrap {
  position: relative;
  width: 100%;
  display: block;

  & .errorIcon {
    position: absolute;
    left: auto;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    & svg {
      @media(max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}