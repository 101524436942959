.tooltip {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  cursor: pointer;
  @media (max-width: 767px) {
    cursor: default;
  }
}

.tooltip .tooltipText {
  text-align: left;
  width: 250px;
  position: absolute;
  /* bottom: -85px; */
  left: -3px;
  top: 25px;
  margin-top: 20px;
  background: #f6f3f3;
  padding: 16px;
  color: #000;
  z-index: 5;
  border-radius: 5px;
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #3C434D;
  font-family: Kanit;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 125% */

  & svg {
    margin-right: 5px;
    margin-bottom: 4px;
  }

  @media (max-width: 767px) {
    width: 240px;
  }
}

.tooltip .tooltipText::after {
  content: '';
  position: absolute;
  top: -19px;
  left: 7px;
  border-width: 10px;
  border-style: solid;
  border-color: #c4c4c4 transparent transparent transparent;
  transform: rotate(180deg);
}