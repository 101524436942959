.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.track {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -4px;
  width: 100%;
  height: 8px;
  box-sizing: content-box;
  background-color: var(--colorGrey100);
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 10%);
}

.range {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  height: 8px;
  background-color: var(--marketplaceColor);
}
