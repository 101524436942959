@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: unset;
  width: 400px;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: 10px;
    background-color: var(--colorWhite);
    margin-top: 10px;
    margin-bottom: 12.5vh;
    border-radius: 2px;
    /* border: 1px solid #e6e6e6; */
    border-bottom: unset;
    background: #fff;
    width: 100%;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
}
.cardBanner{
  /* margin-left: 34px; */
  @media (max-width: 767px) {
    margin: 48px 24px 0;
  }
}
.modalContents {
  @media (max-width: 767px) {
    margin: 0 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
}
.modalContents {
  @media (max-width: 767px) {
    margin: 0 24px;
  }
}
.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;
  display: none;
  @media (--viewportMedium) {
    padding: 0;
  }
  @media (max-width: 1023px) {
    margin-top: 0;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}
.orderPanelModal{
   /* display: block; */
}
.author {
  composes: p from global;
  margin: 0px 24px 32px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  /* display: none; */
  margin-right: 10px;
  @media (--viewportLarge) {
    display: flex;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    /* display: none; */
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  /* display: none; */

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: #4A4A4A;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 152.381% */
  letter-spacing: -0.75px;

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}
.orderHeadingAddress {
  /* Font */
& h4{
  color: #00C3A0;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 152.381% */
  letter-spacing: -0.75px;

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
