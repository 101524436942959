.field {
  & p {
    color: red;
    font-size: small;
  }
  & > div {
    & > div {
      /* border: 1px solid #e6e6e6;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='8' viewBox='0 0 17 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L8.5 8L17 0H0Z' fill='%231F2A2D'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right 19px top 26px;
      max-height: 59px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      border-radius: unset;
      cursor: pointer;
      @media (max-width: 767px) {
        background-position: right 19px top 18px;
      } */
      & > div {
        /* padding-top: 0; */
        & > div {
          color: #3c434d;
          font-family: Kanit;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.18px;
          padding-right: 36px;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
      /* & > div:nth-child(2) {
        display: none;
      } */
      & input {
        box-shadow: none;
        border: none;
        height: auto;
      }
    }
  }
}
