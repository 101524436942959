@import '../../styles/customMediaQueries.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
.contentMain {
  border: 1px solid #000;
  max-width: 100%;
  margin-top: 68px;

  @media (--viewportLarge) {
    max-width: calc(100vw - 416px);
  }
 @media (max-width: 1023px) {
  max-width: calc(100vw - 140px);
 }
 @media (max-width: 767px) {
  max-width: calc(100vw - 40px);
  margin-top: 32px;
  margin-bottom: 45px;
 }
  /* width: 1057px;
  height: 208px;
  flex-shrink: 0; */

  & .card {
    border-right: 1px solid #000;
    padding: 35px 22px 54px;
    /* display: flex; */

    & .packageName {
      color: #000;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.64px;
      margin-top: 0;
      margin-bottom: 9px;
      padding: 0;
      @media (max-width: 1480px) {
        font-size: 18px;
      }
    }

    & .packagePrice {
      color: #000;
      font-family: Montserrat;
      font-size: 33px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 3.96px;
      margin-bottom: 41px;
      @media (max-width: 1480px) {
        font-size: 28px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
      }
      & svg {
        fill: none;
      }
    }

    & .voltNo {
      color: #21201F;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0;
      margin-left: 8px;
    }

    & .acornsCount {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #21201f;
      margin-bottom: 18px;
    }

    & button {
      width: 100%;
      cursor: pointer;
      border-radius: 1px;
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.16px;
      @media (max-width: 767px) {
        cursor: default;
      }
    }

    & .dividerLine {
      background-color: #d4d4d4;
      height: 1px;
      margin-top: 29px;
      margin-bottom: 30px;
    }

    & ul {
      margin: 0;

      & li {
        & .checkIcon {
          margin-right: 13px;
        }
      }
    }

    & .acornsDescription {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #4a4a4a;
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
}

.purchaseButton {
  background-color: #000 !important;
  color: #fff;

  &:hover {
    background-color: #21201f;
  }
}
