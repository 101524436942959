.root {
  position: relative;
  /* margin: 0 10px; */
  width: 100%;
  height: 24px;
}

.activeHandle {
  z-index: 1;
}

.slider {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}

/* .minBox {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.maxBox {
  position: absolute;
  top: 50%;
  right: -11px;
  transform: translate(-50%, -50%);
} */

.trackBox {
  /* width: 100%;
  margin: 0 30px; */
}
